html,
body,
#root,
canvas {
  margin: 0;
  padding: 0;
  height: 900px;
}


.content {
  display: block;
  position: sticky;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #202035;
  color: white;
  padding: 10px 10px;
  opacity: 90%;
  border-radius: 5px;
}
